<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number mb-0">
          <router-link
              :to="$store.getters.GET_PATHS.products + '?id=' + product.product_id + `&type=${$route.query.storage ? $route.query.storage : 1}`"
              :target="'_blank'"
              class="table-link btn-style">
            #<ValueHelper
              style="margin-left: -3px;"
              :value="product"
              :deep="'product_id'"
          />
          </router-link>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('productsReport_Name.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{product.product[0].name_for_fba}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_Warehouse.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{getTypeName(item)}}
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_Balance.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="product"
                  :deep="'total_quantity_before'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_Tracking.localization_value.value')}}
            </div>
            <div class="table-card__item-info" style="word-break: break-word;">
              <ValueHelper
                  :value="item"
                  :deep="'order_model.0.object.tracking_number'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_DateShip.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.shipped_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div v-if="navTabs.received.active" class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_DateReceived.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.received_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              <span v-if="navTabs.received.active || navTabs.transit.active">#{{$t('productsReport_Inbound.localization_value.value')}}</span>&nbsp;
              <span v-if="navTabs.received.active">#{{$t('productsReport_Outbound.localization_value.value')}}</span>
              <span v-if="navTabs.all.active">#{{$t('productsReport_InboundOutbound.localization_value.value')}}</span>
            </div>
            <div class="table-card__item-info">
              <router-link
                  :to="getTypeLink(item)"
                  :target="'_blank'"
                  class="table-link btn-style">
                <ValueHelper
                    :value="item"
                    :deep="'order_model.0.object.id'"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_CountChange.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="product"
                  :deep="'effected_quantity'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_CountTotal.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="product"
                  :deep="'total_quantity_after'"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
import {productsReportMixin} from "@/mixins/productsReportMixins/productsReportMixin";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
export default {
  name: "ProductsReportTableMobile",

  components: {ValueHelper},

  mixins: [productsReportMixin],

  props: {
    item: Object,
    product: Object,
    navTabs: Object,
    filterGetParams: Object,
  },
  
  data() {
    return {
      show: false,
    }
  },
  
}
</script>

<style scoped>

</style>