<template>
  <div>
    <ProductsReportHead
        :navTabs="navTabs"
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @changeTab="(data) => $emit('changeTab', data)"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeFilter="changeFilter"
    />

    <div class="table-filter-wrap">

      <ProductsReportFilter
          :navTabs="navTabs"
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          @close="closeFilter"
          @changeFilter="changeFilter"
          @resetFilter="$emit('resetFilter')"
      />

      <ProductsReportTable
          v-if="navTabs.all.active || navTabs.received.active || navTabs.shipped.active || navTabs.damage.active"
          :navTabs="navTabs"
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />

      <ProductsReportTableTransit
          v-if="navTabs.transit.active"
          :navTabs="navTabs"
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />

      <ProductsReportTableReserve
          v-if="navTabs.reserve.active"
          :navTabs="navTabs"
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
      />
    </div>
  </div>
</template>

<script>


  import ProductsReportHead from "./ProductsReportHead/ProductsReportHead";
  import ProductsReportFilter from "./ProductsReportFilter/ProductsReportFilter";
  import ProductsReportTable from "./ProductsReportTable/ProductsReportTableAll/ProductsReportTable";
  import ProductsReportTableTransit
    from "@/components/modules/ProductsReportModule/components/ProductsReportTable/ProductsReportTableUser/ProductsReportTable/ProductsReportTableTransit/ProductsReportTableTransit";
  import ProductsReportTableReserve
    from "@/components/modules/ProductsReportModule/components/ProductsReportTable/ProductsReportTableUser/ProductsReportTable/ProductsReportTableReserve/ProductsReportTableReserve";

  export default {
    name: "ProductsReportTableUser",

    components: {
      ProductsReportHead,
      ProductsReportFilter,
      ProductsReportTable,
      ProductsReportTableTransit,
      ProductsReportTableReserve,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },
    }
  }
</script>

<style scoped>

</style>
