<template>
  <div class="fragment">


    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'productsReport_all',
          'productsReport_received',
          'productsReport_transit',
          'productsReport_shipped',
          'productsReport_reserve',
          'productsReport_damage',
        ])"></div>
      <div class="content-tabs content-tabs--separator"
           v-if="Object.keys($store.getters.getUserProfile).length > 0"
      >
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";


  export default {
    name: "ProductsReportHead",
    components: {
      ToggleFilterButton,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
      }
    },

    methods: {

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block{
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992{
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block{
      flex-wrap: wrap;
    }

    .main-button{
      margin-bottom: 10px;
    }

  }

  @include for-420{
    .light-bg-block{
      flex-direction: column-reverse;
      align-items: flex-start;
    }

  }

</style>

