<template>
  <div class="table-filter-wrap">
    <template
        v-if="$store.getters.getProductsReport.length > 0 && $store.getters.getProductsReportLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
              'productsReport_ProductID',
              'productsReport_Name',
              'productsReport_Warehouse',
              'productsReport_Balance',
              'productsReport_Tracking',
              'productsReport_DateShip',
              'productsReport_Inbound',
              'productsReport_Outbound',
              'productsReport_InboundOutbound',
              'productsReport_CountChange',
              'productsReport_CountTotal',
              ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('productsReport_ProductID.localization_value.value')}}</th>
            <th>{{$t('productsReport_Name.localization_value.value')}}</th>
            <th>{{$t('productsReport_Warehouse.localization_value.value')}}</th>
            <th>{{$t('productsReport_Balance.localization_value.value')}}</th>
            <th>{{$t('productsReport_Tracking.localization_value.value')}}</th>
            <th>{{$t('productsReport_DateShip.localization_value.value')}}</th>
            <th>#{{$t('productsReport_Inbound.localization_value.value')}}</th>
            <th>{{$t('productsReport_CountChange.localization_value.value')}}</th>
            <th>{{$t('productsReport_CountTotal.localization_value.value')}}</th>
            <th width="100" class="hide-1600"></th>
          </tr>
          </thead>
          <tbody>

          <template v-for="(item, index) in $store.getters.getProductsReport">
            <template v-for="(product, indexProduct) in item.products">
              <tr
                  v-if="showWithFilterProduct(product)"
                  :key="index + parseFloat(Math.random()).toFixed(3) + product.product.id + product.countInStorage + product.quantity * 3 + indexProduct">
                <td>
                  <router-link
                      :to="$store.getters.GET_PATHS.products + '?id=' + product.product_id + `&type=${$route.query.storage ? $route.query.storage : 1}`"
                      :target="'_blank'"
                      class="table-link btn-style">
                    #<ValueHelper
                      style="margin-left: -3px;"
                      :value="product.product.id"
                  />
                  </router-link>
                </td>
                <td>
                  <div v-if="product.product.name_for_fba" style="max-width: 450px" class="word-break white-space-normal">{{product.product.name_for_fba}}</div>
                  <div v-else-if="product.product.name_for_fbm" style="max-width: 450px" class="word-break white-space-normal">{{product.product.name_for_fbm}}</div>
<!--                  <div class="table-row">-->
<!--                    <TooltipTextHelper-->
<!--                        v-if="product.product.name_for_fba"-->
<!--                        :text="product.product.name_for_fba"-->
<!--                        :paragraph="product.product.name_for_fba"-->
<!--                        :fullTextWidth="'450'"/>-->
<!--                    <TooltipTextHelper-->
<!--                        v-else-if="product.product.name_for_fbm"-->
<!--                        :text="product.product.name_for_fbm"-->
<!--                        :paragraph="product.product.name_for_fbm"-->
<!--                        :fullTextWidth="'450'"/>-->
<!--                  </div>-->
                </td>

                <td>
                  <div class="site-status d-flex align-items-center">
                    {{$t('productsReport_transit.localization_value.value')}}
                  </div>
                </td>
                <td>
                  <ValueHelper
                      :value="product.countInTransitBefore"
                  />
                </td>
                <td>
                  <ValueHelper
                      :value="item.tracking_number"
                  />
                </td>
                <td>
                  <ValueHelper
                      :value="item.created_at"
                      :date="true"
                      :dateType="'DD MMM, YYYY'"
                  />
                </td>
                <td>
                  <router-link
                      v-if="item.object_type === 'order_inbound'"
                      :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                      getOrderType(item['fulfillment'], item['return']).value +
                      '/' + item.id"
                      :target="'_blank'"
                      class="table-link btn-style">
                    <ValueHelper
                        :value="item"
                        :deep="'id'"
                    />
                  </router-link>
                  <router-link
                      v-if="item.object_type === 'consolidation'"
                      :to="$store.getters.GET_PATHS.ordersConsolidationEditing + '/' +
                    getConsolidationType({consolidation_destination: {delivery_service_id: item.delivery_service_id}}) + '/' + item.id"
                      :target="'_blank'"
                      class="table-link btn-style">
                    <ValueHelper
                        :value="item"
                        :deep="'id'"
                    />
                  </router-link>
                </td>
                <td>
                  <ValueHelper
                      :value="product"
                      :deep="'quantity'"
                  />
                </td>
                <td>
                  {{parseInt(product.quantity) + parseInt(product.countInTransitBefore)}}
                </td>
                <!--            <td>23 Oct, 2020</td>-->
                <!--            <td align="right">$ 34</td>-->
                <!--            <td align="right">$ 53</td>-->
                <!--            <td align="right">$ 53</td>-->
                <td class="hide-1600"></td>
              </tr>
            </template>
          </template>


          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <template v-for="(item, index) in $store.getters.getProductsReport">
              <div class="table-card__item"
                   v-for="(product, indexProduct) in item.products"
                   :key="index + product.product.id + product.countInStorage + product.quantity * 3 + indexProduct"
                   v-bind:class="{'d-none': !showWithFilterProduct(product)}"
              >
                <ProductsReportTableMobile
                    :product="product"
                    :item="item"
                    :filterGetParams="filterGetParams"
                />
              </div>
            </template>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                v-if="$store.getters.getProductsReportCommonList.next_page_url !== null && $store.getters.getProductsReport.length > 0"
                @click.native="$emit('showMore')"
                v-bind:class="{'disabled-btn' : $store.getters.getNextProductsReportPage}"
                :count="$store.getters.getProductsReportCommonList.total - $store.getters.getProductsReportForPage < $store.getters.getProductsReportForPage ?
                      $store.getters.getProductsReportCommonList.total - $store.getters.getProductsReportForPage:
                      25"
            />
            <ExportBtn
              :customType="_.has($store.getters.getProductsReportCommonList, 'addition_export') ? $store.getters.getProductsReportCommonList.addition_export : null"
              @downloadFiles="type => $emit('downloadFiles', type)"
              class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>

    <template
        v-if="$store.getters.getProductsReportLoading === false && $store.getters.getProductsReport.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>


    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


  </div>
</template>

<script>
import ShowMore from "../../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
import {mixinDetictingMobile} from '../../../../../../../../mixins/mobileFunctions'
// import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
// import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
import {productsReportMixin} from "@/mixins/productsReportMixins/productsReportMixin";
import ProductsReportTableMobile
  from "@/components/modules/ProductsReportModule/components/ProductsReportTable/ProductsReportTableUser/ProductsReportTable/ProductsReportTableTransit/ProductsReportTableMobile/ProductsReportTableMobile";
import {consolidationMixinHelper} from "@/mixins/consolidationMixins/consolidationMixinHelper";
import ExportBtn from "@/components/coreComponents/Buttons/Export/Export";


export default {
  name: "ProductsReportTableTransit",

  components: {
    ExportBtn,
    // TooltipTextHelper,
    ProductsReportTableMobile,
    ValueHelper,
    NoResult,
    ShowMore,
    // StatusIcoBtn,
  },

  mixins: [mixinDetictingMobile, productsReportMixin, consolidationMixinHelper],

  props: {
    countFilterParams: Number,
    filterGetParams: Object,
    navTabs: Object,
  },

  data() {
    return {

    }
  },

  methods: {
    showWithFilterProduct(product) {
      if(!this.filterGetParams.productId) return true

      if(product.product_id === parseInt(this.filterGetParams.productId)) return true

      return false
    },
  }

}

</script>

<style scoped>

.site-table-wrap {
  margin-top: -15px;
}

.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

@media (max-width: 1600px) {
  .comment-row {
    width: 100px;
  }
}

.site-table thead th:first-child,
.site-table tbody td:first-child {
  padding-right: 0;
}

.small-table th,
.small-table td {
  white-space: nowrap;
}

</style>
