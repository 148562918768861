<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <div class="table-card__item-number mb-0">
          <router-link
              :to="$store.getters.GET_PATHS.products + '?id=' + product.product_id + `&type=${$route.query.storage ? $route.query.storage : 1}`"
              :target="'_blank'"
              class="table-link btn-style">
            #<ValueHelper
              style="margin-left: -3px;"
              :value="product.product.id"
          />
          </router-link>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('productsReport_Name.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="product.product.name_for_fbm">
                <ValueHelper
                    :value="product.product.name_for_fbm"/>
              </template>
              <template v-else-if="product.product.name_for_fba">
                <ValueHelper
                    :value="product.product.name_for_fba"/>
              </template>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_Warehouse.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.object_type === 'order_fbm' ? $t('productsReport_fbm.localization_value.value') : $t('productsReport_fba.localization_value.value')}}
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_Balance.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="product.countInReserveBefore"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_Tracking.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.tracking_number"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_DateShip.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.created_at"
                  :date="true"
                  :dateType="'DD MMM, YYYY'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              #{{$t('productsReport_Inbound.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <router-link
                  v-if="item.object_type === 'order_fbm'"
                  :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getOrderFBMTypeById(item.type).name + '/' + item.id"
                  :target="'_blank'"
                  class="table-link btn-style">
                <ValueHelper
                    :value="item.id"
                />
              </router-link>
              <router-link
                  v-else
                  :to="$store.getters.GET_PATHS.ordersFBA + '/show/' + item.id"
                  :target="'_blank'"
                  class="table-link btn-style">
                <ValueHelper
                    :value="item.id"
                />
              </router-link>
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_CountChange.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="product"
                  :deep="'quantity'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('productsReport_CountTotal.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{parseInt(product.quantity) + parseInt(product.countInReserveBefore)}}
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
import {productsReportMixin} from "@/mixins/productsReportMixins/productsReportMixin";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
export default {
  name: "ProductsReportTableMobile",

  components: {ValueHelper},

  mixins: [productsReportMixin],

  props: {
    product: Object,
    item: Object,
    navTabs: Object,
    filterGetParams: Object,
  },
  
  data() {
    return {
      show: false,
    }
  },
  
}
</script>

<style scoped>

</style>