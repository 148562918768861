
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
<!--      <div class="filter-wrapper-scoped">-->

<!--        <div class="section-label d-flex">-->
<!--          Report Type for Warehouse-->
<!--          <v-popover-->
<!--              class="site-tooltip"-->
<!--              offset="5"-->
<!--              placement="top"-->
<!--              trigger="hover"-->

<!--          >-->
<!--            <TooltipBtn-->
<!--                class="tooltip-target"-->

<!--            />-->
<!--            <template slot="popover">-->
<!--              <p>-->
<!--                <b>Report Type for Warehouse</b>-->
<!--              </p>-->
<!--              <p>-->
<!--                Random text-->
<!--              </p>-->
<!--            </template>-->
<!--          </v-popover>-->
<!--        </div>-->

<!--        <div class="custom-row">-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'In Transit'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'Reserved FBA'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'FBA (self-shipping)'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'Demaged'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'Reserved FBM'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'FBM (self-shipping)'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'Returns'"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DefaultCheckbox-->
<!--              :label="'Shipped'"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="section-label d-flex mt-3">-->
<!--          Products-->
<!--          <v-popover-->
<!--              class="site-tooltip"-->
<!--              offset="5"-->
<!--              placement="top"-->
<!--              trigger="hover"-->

<!--          >-->
<!--            <TooltipBtn-->
<!--                class="tooltip-target"-->

<!--            />-->
<!--            <template slot="popover">-->
<!--              <p>-->
<!--                <b>Products</b>-->
<!--              </p>-->
<!--              <p>-->
<!--                Random text-->
<!--              </p>-->
<!--            </template>-->
<!--          </v-popover>-->
<!--        </div>-->

<!--        <div class="custom-row">-->
<!--          <div class="custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;md-100 mb-3">-->
<!--            <DefaultInput-->
<!--              :label="'Products'"-->
<!--              :type="'text'"-->
<!--              v-model="input01"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100 mb-3">-->
<!--            <DefaultInput-->
<!--                :label="'User’s Name'"-->
<!--                :type="'text'"-->
<!--                v-model="input02"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="custom-col">-->
<!--            <DefaultCheckbox-->
<!--                :label="'All Products'"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="section-label d-flex mt-3">-->
<!--          Period-->
<!--          <v-popover-->
<!--              class="site-tooltip"-->
<!--              offset="5"-->
<!--              placement="top"-->
<!--              trigger="hover"-->

<!--          >-->
<!--            <TooltipBtn-->
<!--                class="tooltip-target"-->

<!--            />-->
<!--            <template slot="popover">-->
<!--              <p>-->
<!--                <b>Period</b>-->
<!--              </p>-->
<!--              <p>-->
<!--                Random text-->
<!--              </p>-->
<!--            </template>-->
<!--          </v-popover>-->
<!--        </div>-->

<!--        <div class="custom-row">-->
<!--          <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100 mb-3">-->
<!--            <DatePickerDefault-->
<!--                :label="'Date'"-->
<!--                v-model="date"-->
<!--            >-->
<!--              <template slot="body">-->
<!--                <date-picker-->
<!--                    v-model="date"-->
<!--                    ref="datePicker"-->
<!--                    valueType="format"-->
<!--                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                    :format="'MM/DD/YY'"-->
<!--                    :placeholder="'mm/dd/yy'"-->
<!--                ></date-picker>-->
<!--              </template>-->
<!--            </DatePickerDefault>-->
<!--          </div>-->
<!--          <div class="custom-col">-->
<!--            <DefaultCheckbox-->
<!--                :label="'Whole Period'"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

        <div class="table-filter__item"
             v-if="isAdmin"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['common_user'])"></span>

          <SearchSelect
              :options="options"
              :label="$t('common_user.localization_value.value')"
              :functionSearch="functionSearchUserFilter"
              @change="changeUsersFilter"
              :selected="userName"
              :typeSelect="'users'"
          />
        </div>

        <div class="table-filter__item">
          <DefaultSelect
              class="w-100"
              :options="optionsProducts"
              :optionsLabel="'name_for_fbm'"
              :label="$t('productsReport_Name.localization_value.value')"
              :selected="productId ? {id: productId, name_for_fbm: productName} : ''"
              :disabled="userId === '' && isAdmin"
              @change="changeProductFilter"
          />
          <!--              @change="setStatus"-->
        </div>

        <div class="table-filter__item" v-if="navTabs.all.active">
          <DefaultSelect
              class="w-100"
              :optionsLabel="'label'"
              :options="optionsWarehouse"
              :label="$t('productsReport_Warehouse.localization_value.value')"
              :selected="_.find(optionsWarehouse, {value: warehouseType})"
              @change="changeWarehouseFilter"
          />
          <!--              @change="setStatus"-->
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['payPalCases_closingDate'])"></span>
          <DatePickerDefault
              :label="$t('productsReport_DateShip.localization_value.value')"
              v-model="date"
          >
            <template slot="body">
              <date-picker
                  v-model="date"
                  ref="datePicker"
                  valueType="format"
                  range
                  :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                  :format="'MM/DD/YY'"
                  :placeholder="'mm/dd/yy'"
              ></date-picker>
            </template>
          </DatePickerDefault>
        </div>

      <div class="table-filter__item">
        <DefaultSelect
            class="w-100"
            :optionsLabel="'name'"
            :options="optionsStorage"
            :label="$t('productsReport_Storage.localization_value.value')"
            :selected="_.find(optionsStorage, {id: parseInt(storage)})"
            @change="changeStorageFilter"
        />
      </div>

<!--        <div class="table-filter__item">-->
<!--          <DefaultInput-->
<!--              :label="'Id'"-->
<!--              :type="'text'"-->
<!--          />-->
<!--&lt;!&ndash;              v-model="complaintId"&ndash;&gt;-->
<!--        </div>-->

<!--      </div>-->
    </template>
    <template slot="footer">
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";
  // import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "ProductsReportFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      // DefaultInput,
      DefaultSelect,
      // TooltipBtn,
      // DefaultCheckbox,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      filterGetParams: Object,
      navTabs: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY'): '',
        // name: this.filterGetParams.name ? this.filterGetParams.name : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        productId: this.filterGetParams.productId ? this.filterGetParams.productId : '',
        productName: this.filterGetParams.productName ? this.filterGetParams.productName : '',

        warehouseType: this.filterGetParams.warehouseType ? this.filterGetParams.warehouseType : '',

        storage: this.filterGetParams.storage ? this.filterGetParams.storage : '',

        options: [],
        optionsProducts: [],
        optionsWarehouse: [
          {label: this.$t('productsReport_Outbound.localization_value.value'), value: 'outbound'},
          {label: this.$t('productsReport_Inbound.localization_value.value'), value: 'inbound'},
          {label: this.$t('productsReport_inventory.localization_value.value'), value: 'inventory'},
          // {label: this.$t('productsReport_disposal.localization_value.value'), value: 'disposal'},
        ],

        optionsStorage: [],

        filterCounts: [
          'date',
          // 'openedDate',
          // 'closedDate',
          'userName',
          'userId',
          'productId',
          'productName',
          'warehouseType',
          'storage',
          // 'complaintTypeId',
          // 'complaintTypeName',
          // 'reasonsId',
          // 'reasonsName',
          // 'status',
          // 'externalComplainId',
          // 'complaintId',
        ],

      }
    },

    watch: {
      filterGetParams(newVal) {
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
        this.name = newVal.name ? newVal.name : ''

        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''

        this.productId = newVal.productId ? newVal.productId : ''
        this.productName = newVal.productName ? newVal.productName : ''

        this.warehouseType = newVal.warehouseType ? newVal.warehouseType : ''

        this.storage = newVal.storage ? newVal.storage : ''

        this.getUserProducts()
      },

    },

    mounted() {
      this.getUserProducts()
      this.getWarehouseStorages()
    },

    methods: {

      getUserProducts() {
        if(this.isAdmin && this.userId === '') return

        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')
        myQuery.where('ProductUserId', this.userId)
        myQuery.where('ProductInDraft', 0)
        url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

        let typeFetch = 'fetchProducts'
        this.$store.dispatch(typeFetch, url).then((response) => {
          this.optionsProducts = this.getRespPaginateData(response)
        })
      },

      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          this.optionsStorage = this.getRespPaginateData(response)
        })
      },

      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeUsersFilter(value) {
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name

        this.productId = ''
        this.productName = ''
        this.getUserProducts()
      },

      changeProductFilter(value) {
        this.productId = value.id
        this.productName = value.name_for_fbm
      },

      changeWarehouseFilter(val) {
        this.warehouseType = val.value
      },

      changeStorageFilter(val) {
        this.storage = val.id
      },
    },
  }
</script>

<style scoped>
  .filter-wrapper-scoped{
    max-width: 915px;
    width: 100%;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
  }

</style>
