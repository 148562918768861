import {FBMMixinsHelper} from "@/mixins/FBMMixins/FBMMixins";
import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";
import {consolidationMixinHelper} from "@/mixins/consolidationMixins/consolidationMixinHelper";

export const productsReportMixin = {

  mixins: [FBMMixinsHelper, ordersInboundMixin, consolidationMixinHelper],

  methods: {
    getTypeName(item) {
      if(item.order_model.length === 0) return
      let type = item.operation_type
      let object = item.order_model[0].object

      switch (type) {
        case 'outbound' :
          if(!object.type) {
            return this.$t('productsReport_fba.localization_value.value')
          }
          if(object.type === 'return-document') {
            return this.$t('productsReport_OrderMeest.localization_value.value')
          }
          return this.$t('productsReport_fbm.localization_value.value')
        case 'inventory' :
          return this.$t('productsReport_inventory.localization_value.value')
        case 'inbound' :
          return this.$t('productsReport_Inbound.localization_value.value')
        case 'disposal' :
          return this.$t('productsReport_disposal.localization_value.value')
        case 'income' :
          return this.$t('productsReport_income.localization_value.value')
        case 'consolidation_inbound' :
          return this.$t('productsReport_consolidation.localization_value.value')
      }
    },

    getTypeLink(item) {
      if(item.order_model.length === 0) return
      let object = item.order_model[0].object
      let deliveryServiceId = item.order_model[0]?.delivery_service?.id

      let showType = this.isAdmin ? '' : 'show/'

      switch (item.operation_type) {
        case 'outbound' :
          if(!object.type) {
            return this.$store.getters.GET_PATHS.ordersFBA + `/${showType}` + object.id
          }
          if(object.type === 'return-document') {
            return this.$store.getters.GET_PATHS.ordersMeestReturns + `/${showType}` + object.id
          }
          return this.$store.getters.GET_PATHS.ordersFBM + `/${showType}` + this.getOrderFBMTypeById(object.type)?.name + '/' + object.id
        case 'inventory' :
          return this.$store.getters.GET_PATHS.mainSettingsInventory + '/show/' + object.id
        case 'inbound' :
          return this.$store.getters.GET_PATHS.ordersShipmentWarehouse + `/${!this.isAdmin ? 'detail/' : ''}` +
            this.getOrderType(object['fulfillment'], object['return']).value +
            '/' + object.id
        case 'disposal' :
          return this.$store.getters.GET_PATHS.warehouseDisposalOrders + '?id=' + object.id
        case 'income' :
          return this.$store.getters.GET_PATHS.warehouseDisposalOrders + '?id=' + object.id
        case 'consolidation_inbound' :
          return this.$store.getters.GET_PATHS.ordersConsolidation + `/${!this.isAdmin ? 'detail/' : 'edit/'}` +
              this.getConsolidationType({consolidation_destination: {id: object?.consolidation_destination_id, delivery_service_id: deliveryServiceId}}) + '/' + object.id
      }
    },
  },
}
